import React from "react";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import { GatsbyImage } from "gatsby-plugin-image";

const Team = (props) => {
  const team = props.data.directus.team;
  return (
    <Layout bodyClass="page-teams">
      <SEO title="Meet the Team" slug="/team" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Meet The Team</h1>
              <p></p>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row">
          {team.map((edge) => (
            <div
              key={edge.name}
              className="col-12 col-md-6 mb-1"
            >
              <div className="team card-two">
                <div className="card-header">
                  <div className="card-header-left">
                    {edge.image && (
                      <div className="card-image">
                        <GatsbyImage
                          alt={edge.name}
                          className="img-fluid mb-2"
                          image={edge.image.imageFile.childImageSharp.gatsbyImageData}
                        />
                      </div>
                    )}
                  </div>
                  <div className="card-right">
                    <h2 className="card-title">
                      {edge.name}
                    </h2>
                    <ul className="card-meta">
                      <li>
                        <strong>{edge.jobtitel}</strong>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-content">
                {edge.text.split("\n").map((i,key) => {
                  return <div key={key}>{i}</div>;
              })}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TeamQuery {
    directus {
      team(sort: "sort", filter: {status: {_eq: "published"}}) {
        id
        name
        jobtitel
        status
        text
        image {
          imageFile {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                width: 100
                height: 100
                placeholder: BLURRED
              )
            }
          }
          id
        }
      }
    }
  }
`;

export default Team;
